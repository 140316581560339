import React, { useState } from "react";
import { Spring, animated as a } from "react-spring";
import "./scrolltopbutton.scss";

const ScrollTopButton = ({ scrollToTop }) => {
  const [pressed, setPressed] = useState(false);

  function handleClick() {
    setPressed(false);
    scrollToTop();
  }

  return (
    <div className="mfa__scrollToTop-button-container">
      <Spring native from={{ scale: 1 }} to={{ scale: pressed ? 0.8 : 1 }}>
        {({ scale }) => (
          <a.button
            className="mfa__scrollToTop-button"
            style={{
              transform: scale.to((scale) => `scale(${scale})`),
            }}
            onMouseDown={() => setPressed(true)}
            onClick={handleClick}
            onMouseLeave={() => setPressed(false)}
          >
            &#8679;
          </a.button>
        )}
      </Spring>
    </div>
  );
};

export default ScrollTopButton;
