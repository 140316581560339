import React, { useEffect, useState } from "react";
import { Navbar } from "../../components";
import MultiHeader from "../multiheader/MultiHeader";

import mfaLogo from "../../assets/textlogo.svg";
import "./contact.scss";

const Contact = () => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCopied(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [copied]);
  return (
    <div>
      <div className="gradient__bg">
        <Navbar />
        <MultiHeader
          title="Contact Us"
          desc="Need help? Our support team is available 7 days a week."
        />
      </div>
      <div className="mfa__Contact section__padding">
        <div className="mfa__Contact-container">
          {/* <ContactForm /> */}
          <div className="formContact-content-right-success">
            <div className="formContact-success-text-container">
              <img
                src={mfaLogo}
                alt="formContact-submission"
                className="formContact-img-2"
                style={{ padding: "1rem" }}
              />

              <p
                className="formContact-success-text"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                Please contact us at the email below with your order number and
                we will get back to you as soon as possible!
              </p>
              <p
                style={{
                  fontSize: "14px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                Support email:{" "}
                <span
                  className="formContact-email-text"
                  onClick={() => {
                    navigator.clipboard.writeText("support@merchforall.com");
                    setCopied(true);
                  }}
                >
                  support@merchforall.com
                </span>
                {copied && (
                  <span style={{ color: "#3ae080", paddingLeft: "5px" }}>
                    - Copied!
                  </span>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
