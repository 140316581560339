export const shippingText = [
    {
        heading: "INTERNATIONAL ORDERS:",
        description: "Please note that there may be an additional import tax charged on items valued over $25 when delivering outside of the US. Customs may also hold your order, which could result in, longer than estimated shipping times. International delivery times can take up to 21 business days or longer depending on location. If after 30 days your package still has yet to arrive, please call your local post office and email us with your order number and details. Bear in mind your order may be kept at your nearest post office. The customer is responsible for all import duty and tariff charges that may be applied to international orders."
    },
    {
        heading: "SHIPPING COSTS:",
        description: "Shipping costs are based on weight and the shipping destination. Cost structures are based on standard USPS rates."
    },
    {
        heading: "RETURN POLICY:",
        description: "Because products are made to order, MerchForAll does not accept general returns or sizing-related returns. Should an item be faulty, damaged, or incorrect in any way, it will be replaced with the same item at the cost of Merch For All or you may opt for a full refund instead."
    },
    {
        heading: "EXCHANGES:",
        description: "MerchForAll does not accept exchanges. Should an item be faulty, damaged, or incorrect in any way, it will be subject to our Return Policy. If the wrong size or color was ordered by the customer, a new order must be placed in order to receive the new item."
    }
]