import React from "react";
import { Navbar } from "../../components";
import MultiHeader from "../multiheader/MultiHeader";
import "./privacy.scss";

import { privacyText } from "./privacyText";

const Privacy = () => {
  return (
    <div>
      <div className="gradient__bg">
        <Navbar />
        <MultiHeader
          title="Privacy Policy"
          desc="Please carefully review these Terms and Conditions of Use Before using this site. Any use of this website creates a binding agreement to comply with these Terms and Conditions. If you do not agree with these terms, please exit this site immediately."
        />
      </div>
      <div className="mfa__Privacy section__padding">
        <div className="mfa__Privacy-container">
          <div className="mfa__Privacy-text-group">
            <p className="mfa__Privacy-title">
              This Web site is the responsibility of MerchForAll.com
              (MerchForAll). If you have questions regarding the Agreement or
              the practices of MerchForAll, please contact us by e-mail or
              regular mail.
            </p>
            <br />
            <h1>THE MERCHFORALL COMMITMENT TO PRIVACY</h1>
            <p>
              Your privacy is important to us. To better protect your privacy
              MerchForAll provide this notice explaining our online information
              practices and the choices you can make about the way your
              information is collected and used. To make this notice easy to
              find, MerchForAll has made it available on our homepage and at
              every point where personally identifiable information may be
              requested. The Information MerchForAll Collects: This notice
              applies to all information collected or submitted at
              www.evodesk.com. On some pages, you can order products, make
              requests, and register to receive materials. The types of personal
              information collected at these pages are:
            </p>
            <br />
            <ul>
              <li>Name</li>
              <li>Address</li>
              <li>Email Address</li>
              <li>Phone Number</li>
            </ul>
            <br />
            <h1>CREDIT/DEBIT CARD INFORMATION</h1>
            <p>
              On some pages, you can submit information about other people. For
              example, if you order a gift online and want it sent directly to
              the recipient, you will need to submit the recipient’s address. In
              this circumstance, the types of personal information collected
              are:
            </p>
            <br />
            <ul>
              <li>Name</li>
              <li>Address</li>
              <li>Phone Number</li>
            </ul>
          </div>
          {privacyText.map((text) => {
            return (
              <div className="mfa__Privacy-text-group">
                <h3>{text.heading}</h3>
                <p>{text.description}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Privacy;
