import React, { Component } from "react";
import { Navbar } from "../../components";
import Header from "./SignupHeader";
import "./signup.scss";
import Form from "../form/Form";

export default class Signup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      name: "",
      dialogOpen: false,
    };
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ email: "", name: "" });
  };

  handleChange = (event) => {
    const { value, name } = event.target;

    this.setState({ [name]: value });
  };

  handleClose = () => {
    this.setState({ dialogOpen: false, email: "", name: "" });
  };

  render() {
    return (
      <div>
        <div className="gradient__bg">
          <Navbar />
          <Header />
        </div>
        <div className="mfa__Signup section__padding" id="signup">
          <div className="mfa__Signup-container">
            <h1>Let's get in touch.</h1>
            <Form dialogOpen={this.state.dialogOpen} handleClose />
            {/* <form
              name="contact"
              className="mfa__Signup-form-container"
              method="post"
              onSubmit={this.handleSubmit}
            >
              <input type="hidden" name="form-name" value="contact" />
              <FormInput
                name="name"
                type="text"
                label="Name"
                value={this.state.name}
                handleChange={this.handleChange}
                required
              />
              <FormInput
                type="email"
                name="email"
                label="Email"
                value={this.state.email}
                handleChange={this.handleChange}
                required
              />
              <div className="radio-container">
                <div className="radio-group1">
                  <input
                    type="radio"
                    name="role"
                    id="creatorRole"
                    value={this.state.email}
                    handleChange={this.handleChange}
                    required
                  />
                  <label className="form-input-label-radio" for="creatorRole">
                    Creator
                  </label>
                </div>
                <div className="radio-group">
                  <input
                    type="radio"
                    name="role"
                    value={this.state.email}
                    handleChange={this.handleChange}
                    required
                  />
                  <label
                    className="form-input-label-radio"
                    for="managementRole"
                  >
                    Management
                  </label>
                </div>
              </div>
              <FormInput
                type="email"
                name="email"
                label="Example 1"
                value={this.state.email}
                handleChange={this.handleChange}
                required
              />

              <p>
                <button type="submit">Submit</button>
              </p>
            </form> */}
          </div>
        </div>
      </div>
    );
  }
}
