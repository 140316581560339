import React from "react";
import "./signupheader.scss";
import headerPhoto from "../../assets/example.png";

const Header = () => {
  return (
    <div className="mfa__signupHeader section__padding">
      <div className="mfa__signupHeader-content">
        <h1 className="gradient__text">Get Started</h1>
        <p>
          We are eager to learn more about your merchandising needs, let's get
          in touch.
        </p>
      </div>
      <div className="mfa__signupHeader-image">
        <img src={headerPhoto} alt="MerchForAll Creator Highlights" />
      </div>
    </div>
  );
};

export default Header;
