import React from "react";
import "./ourteamheader.scss";

const Header = () => {
  return (
    <div className="mfa__ourTeamHeader section__padding">
      <div className="mfa__ourTeamHeader-content">
        <h1 className="gradient__text">Our Team</h1>
        <p>Meet the team behind Merch For All</p>
      </div>
    </div>
  );
};

export default Header;
