import { useState, useEffect } from "react";

const useForm = (callback, validate, emailValue) => {
  const [values, setValues] = useState({
    username: "",
    email: "",
    discord: "",
    socials: "",
    role: "",
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);


  const handleChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();


    setErrors(validate(values));
    setIsSubmitting(true);
  };


	
  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback(values);
    }
    if (emailValue && Object.keys(errors).length === 0) {
      setValues({
        username: "",
        email: emailValue,
        discord: "",
        socials: "",
      });
    }
  }, [errors]);

  return { handleChange, handleSubmit, values, errors };
};

export default useForm;
