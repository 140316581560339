import React from "react";
import validate from "./validateInfo";
import useForm from "./UseForm";
import textLogo from "../../assets/textlogo.svg";

import "./formSignup.scss";

const FormSignup = ({ submitForm, emailValue }) => {
  const { handleChange, handleSubmit, values, errors } = useForm(
    submitForm,
    validate,
    emailValue
  );

  return (
    <div className="form-content-right">
      <div className="form-content-logo">
        <img src={textLogo} width="300px" height="75px" alt="logo" />
      </div>
      <form method="POST" onSubmit={handleSubmit} className="form" action="/">
        <h1>
          We are eager to learn more about your merchandising needs. Lets get in
          touch today!
        </h1>
        <div className="form-inputs">
          <label className="form-label">Name</label>
          <input
            className="form-input"
            type="text"
            name="username"
            placeholder="Enter your name"
            value={values.username}
            onChange={handleChange}
          />
          {errors.username && <p>{errors.username}</p>}
        </div>
        <div className="form-inputs">
          <label className="form-label">Email</label>
          <input
            className="form-input"
            type="email"
            name="email"
            placeholder="Enter your email"
            value={values.email}
            onChange={handleChange}
          />
          {errors.email && <p>{errors.email}</p>}
        </div>
        <div class="toggle">
          <label className="form-label">Role</label>
          <div className="toggle-items">
            <input
              className="form-radio-input"
              type="radio"
              name="role"
              value="Creator"
              id="creatorCheck"
              checked={values.role === "Creator"}
              onChange={handleChange}
            />
            <label for="creatorCheck">Creator</label>
            <input
              className="form-radio-input"
              type="radio"
              name="role"
              value="Management"
              id="managementCheck"
              checked={values.role === "Management"}
              onChange={handleChange}
            />
            <label for="managementCheck">Management</label>
          </div>
        </div>
        <div className="form-inputs">
          <label className="form-label">Discord</label>
          <input
            className="form-input"
            type="text"
            name="discord"
            placeholder="Enter your discord"
            value={values.discord}
            onChange={handleChange}
          />
        </div>
        <div className="form-inputs">
          <label className="form-label">Socials</label>
          <input
            className="form-input"
            type="text"
            name="socials"
            placeholder="Enter your socials"
            value={values.socials}
            onChange={handleChange}
          />
          {errors.socials && <p>{errors.socials}</p>}
        </div>

        <button className="form-input-btn" type="submit">
          Submit
        </button>
        <span className="form-input-login">Currently accepting partners.</span>
      </form>
    </div>
  );
};

export default FormSignup;
