import React from "react";
import promoVideo from "../../assets/promoVideo.mp4";
import "./promo.scss";

const Promo = () => {
  return (
    <div className="section__padding" id="promo">
      <div className="mfa__cta">
        <div className="mfa__cta-content">
          <div className="mfa__cta-bar" />
          <h3>Your brand. Taken to new heights.</h3>
          <p>
            From phone accessories to apparel, MerchForAll is here to push the boundaries of creativity.
          </p>
          <div className="mfa__cta-video">
            <video loop autoPlay muted playsInline>
              <source src={promoVideo} type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Promo;
