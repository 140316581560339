import React from "react";
import { Navbar } from "../../components";
import MultiHeader from "../multiheader/MultiHeader";

const PageNotFound = () => {
  return (
    <>
      <div className="gradient__bg" id="shipping">
        <Navbar />
        <MultiHeader
          title="Page Not Found"
          desc="Proceed back to the home page."
        />
      </div>
    </>
  );
};

export default PageNotFound;
