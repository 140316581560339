export const privacyText = [
    {
        heading: "THE WAY MERCHFORALL USES INFORMATION:",
        description: "MerchForAll uses the information you provide about yourself when placing an order only to complete that order. MerchForAll does not share this information with outside parties except to the extent necessary to complete that order. MerchForAll uses the information you provide about someone else when placing an order only to ship the product and to confirm delivery. MerchForAll does not share this information with outside parties except to the extent necessary to complete that order. MerchForAll uses return email addresses to answer email that we receive. Such addresses are not shared with outside parties. MerchForAll uses non-identifying and aggregate information to better design our MerchForAll.com site and to share with advertisers. For example, evoesk may tell an advertiser that X number of individuals visited a certain area on our MerchForAll website, or that Y number of men and Z number of women filled out our registration form, but MerchForAll would not disclose anything that could be used to identify those individuals. Finally, MerchForAll never uses or share the personally identifiable information provided to us online in ways unrelated to the ones described above without also providing you an opportunity to opt-out or otherwise prohibit such unrelated uses."
    },
    {
        heading: "OUR COMMITMENT TO DATA SECURITY",
        description: "To prevent unauthorized access, maintain data accuracy, and ensure the correct use of information, MerchForAll has put in place appropriate physical, electronic, and managerial procedures to safeguard and secure the information MerchForAll.com collects online."
    },
    {
        heading: "OUR COMMITMENT TO CHILDREN’S PRIVACY:",
        description: "Protecting the privacy of the very young is especially important. For that reason, MerchForAll never collects or maintain information at MerchForAll.com from those we actually know are under 13, and no part of our MerchForAll.com is structured to attract anyone under 13. You can access all your personally identifiable information that MerchForAll collects online and maintain by calling us and verifying your identitiy. MerchForAll.com uses this procedure to better safeguard your information. You can correct factual errors in your personally identifiable information by sending us a request that credibly shows error. To protect your privacy and security, MerchForAll will also take reasonable steps to verify your identity before granting access or making corrections."
    },
    {
        heading: "EXCHANGES",
        description: "Merch For All does not accept exchanges. Should an item be faulty, damaged, or incorrect in any way, it will be subject to our Return Policy. If the wrong size or color was ordered by the customer, a new order must be placed in order to receive the new item. All refunds will be issued once the product has been returned to our facilities."
    },
    {
        heading: "MERCHFORALL’S RIGHT TO CONTACT USER",
        description: "MerchForAll reserves the right to contact site visitors regarding account status and changes to the subscriber agreement, privacy policy, or any other policies or agreements relevant to site visitors."
    },
    {
        heading: "MERCHFORALL’S RIGHT TO CHANGE PRIVACY POLICY.",
        description: "MerchForAll reserves the right to change this policy at any time by notifying visitors of the existence and location of the new or revised privacy policy."
    },
    {
        heading: "HOW TO CONTACT US",
        description: "Should you have other questions or concerns about these privacy policies, please send contact us by visting our contact us page"
    }
]