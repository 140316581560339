import React from "react";
import {
  Possibility,
  Features,
  WhatMFA,
  Header,
  Integrations,
} from "./index.js";
import { Promo, Navbar } from "../components";

const home = () => {
  return (
    <div>
      <div className="gradient__bg">
        <Navbar />
        <Header />
      </div>
      <Promo />
      <WhatMFA />
      <Possibility />
      <Integrations />
      <Features />
    </div>
  );
};

export default home;
