import React, { useState } from "react";
import { RiCloseLine, RiMenuFill } from "react-icons/ri";
import { NavHashLink as NavLink } from "react-router-hash-link";
import logo from "../../assets/textlogo.svg";
import Form from "../../containers/form/Form";
import "./navbar.scss";

const Menu = () => (
  <>
    <p>
      <NavLink to="/">Home</NavLink>
    </p>
    <p>
      <NavLink to="/#promo">How It Works</NavLink>
    </p>
    <p>
      <NavLink to="/creators">Creators</NavLink>
    </p>
    <p>
      <NavLink to="/our-team">Our Team</NavLink>
    </p>
  </>
);

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [open, setOpen] = useState(false);

  function handleButtonClick() {
    setToggleMenu(false);
    setOpen(!open);
  }

  window.addEventListener("mouseup", (e) => {
    const menu = document.querySelector(".mfa__navbar-menu_container");
    if (e.target !== menu && e.target.parentNode !== menu) {
      setToggleMenu(false);
    }
  });

  return (
    <div className="mfa__navbar" id="home">
      <div className="mfa__navbar-links">
        <div className="mfa__navbar-links_logo">
          <a href="/">
            <img src={logo} alt="MerchForAll - Logo" />
          </a>
        </div>
        <div className="mfa__navbar-links_container">
          <Menu />
        </div>
      </div>
      <div className="mfa__navbar-sign">
        <button type="button" onClick={handleButtonClick}>
          Get Started
        </button>
      </div>
      <div className="mfa__navbar-menu">
        {toggleMenu ? (
          <RiCloseLine
            className="scale-up-center"
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(false)}
          />
        ) : (
          <RiMenuFill
            className="scale-up-center"
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(true)}
          />
        )}

        {toggleMenu && (
          <div className="mfa__navbar-menu_container scale-up-center">
            <div className="mfa__navbar-menu_container-links">
              <Menu />
              <div className="mfa__navbar-menu_container-links-sign">
                <button type="button" onClick={handleButtonClick}>
                  Get Started
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <Form dialogOpen={open} handleClose={handleButtonClick} />
    </div>
  );
};

export default Navbar;
