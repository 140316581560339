import React from "react";
import "./creatorsheader.scss";
import headerGif from "../../assets/creatorGif.gif";

const Header = () => {
  return (
    <div className="mfa__CreatorsHeader section__padding">
      <div className="mfa__CreatorsHeader-content">
        <h1 className="gradient__text">Branded Web Store</h1>
        <p>
          We all know the importance of having your own branding and logo on
          your online store. With MerchForAll you'll get your own online web
          store, fully customized in appearance and functionality to fit your
          brand.
        </p>
      </div>
      <div className="mfa__CreatorsHeader-image">
        <img src={headerGif} width="752px" height="490px" alt="...loading" />
      </div>
    </div>
  );
};

export default Header;
