import React, { useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { FaTwitter } from "react-icons/fa";
import mfaLogo from "../../assets/textlogo.svg";
import Form from "../form/Form";
import "./footer.scss";

const Footer = () => {
  const [open, setOpen] = useState(false);

  function handleButtonClick() {
    setOpen(!open);
  }

  return (
    <div className="mfa__footer section__padding-footer">
      <div className="mfa__footer-heading">
        <h1 className="gradient__text">
          Your Merch. Your Way. Contact us today.
        </h1>
      </div>

      <div className="mfa__footer-btn" onClick={handleButtonClick}>
        <p>Open My Store</p>
      </div>

      <div className="mfa__footer-links">
        <div className="mfa__footer-links_logo">
          <a href="/#home">
            <img src={mfaLogo} alt="MerchForAll Logo" />
          </a>
          {/* <ul className="mfa__footer-sociallinks-container">
            <li className="mfa__footer-sociallinks-link">
              <FaTwitter />
            </li>
          </ul> */}
          <p>The Superior Merchandising Solution</p>
          <p>Support - support@merchforall.com</p>
        </div>
        <div className="mfa__footer-links_div">
          <h4>About</h4>
          <Link to="/#possibility">What is Merch For All?</Link>
          <Link to="/#features">What do we offer?</Link>
          <Link to="/#how-it-works">Our Products</Link>
          <Link to="/shipping">Shipping and Returns</Link>
          <Link to="/sizing">Sizes and Fitting</Link>
        </div>
        <div className="mfa__footer-links_div">
          <h4>Our Services</h4>
          <Link to="/#possibility">Merchandising</Link>
          <Link to="/#promo">Design Services</Link>
          <Link to="/#features">Client Representative</Link>
          <Link to="/#features">Marketing Consultation</Link>
          {/* <p>Social Media</p> */}
          <Link to="/shipping">International Shipping</Link>
        </div>
        <div className="mfa__footer-links_div">
          <h4>Get in touch</h4>
          <Link to="/our-team">Our Team</Link>
          <a
            href="https://twitter.com/merchforall"
            target="_blank"
            rel="noopener noreferrer"
          >
            Socials
          </a>
          <Link to="/contact">Contact</Link>
        </div>
      </div>
      <div className="mfa__footer-copyright">
        <p>
          Copyright © Merch For All {new Date().getFullYear()} - All rights
          reserved
        </p>
        <p className="mfa__footer-copyright_legal">
          <Link to="/terms">Terms and Conditions</Link> |{" "}
          <Link to="/privacy">Privacy Policy</Link>
        </p>
      </div>
      <Form dialogOpen={open} handleClose={handleButtonClick} />
    </div>
  );
};

export default Footer;
