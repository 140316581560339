import React from "react";
import Feature from "../../components/feature/Feature";
import Fade from "react-reveal/Fade";
import { Carousel as HeaderCarousel } from "react-responsive-carousel";
import bannerImg from "../../assets/whatMFA.jpg";
import whatMFAPlushie from "../../assets/whatMFAPlushie.jpg";
import whatMFAShipping2 from "../../assets/whatMFAShipping2.jpg";
import whatMFAService from "../../assets/whatMFAService.jpg";
import whatMFASlide1 from "../../assets/whatMFASlide1.jpg";
import whatMFASlide2 from "../../assets/whatMFASlide2.jpg";
import whatMFASlide3 from "../../assets/whatMFASlide3.jpg";
import "./whatMFA.scss";

const WhatMFA = () => {
  const headerPhotos = [bannerImg, whatMFASlide1, whatMFASlide2, whatMFASlide3];

  return (
    <div className="section__padding" id="possibility">
      <div className="mfa__whatmfa">
        <Fade delay={200} duration={2000}>
          <div className="mfa__whatmfa-feature">
            <Feature
              title="Fully Custom Merchandise"
              text="Custom retail quality merch designed for you."
            />
            <HeaderCarousel
              autoPlay
              infiniteLoop={true}
              interval={7000}
              showIndicators={false}
              showStatus={false}
              showThumbs={false}
              showArrows={false}
              transitionTime={1000}
              dynamicHeight={true}
            >
              {headerPhotos.map((photo, i) => (
                <img
                  key={i}
                  src={photo}
                  alt={`merchforall feature sample ${i}`}
                />
              ))}
            </HeaderCarousel>
          </div>

          <div className="mfa__whatmfa-container">
            <Feature
              title="Quality Products"
              text="We believe in providing the best experience for the fans and a product influencers are proud to promote."
              image={whatMFAPlushie}
            />
            <Feature
              title="Unbeatable Service"
              text="More than just products, leverage our team for design services, client representative, marketing consultations, social media promotions and more!"
              image={whatMFAService}
            />
            <Feature
              title="Best-In-Class Rates"
              text="Built by content creators, for content creators, with no hidden fees, our compensation packages are unrivaled."
              image={whatMFAShipping2}
            />
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default WhatMFA;
