import React from "react";
import "./feature.scss";

const Feature = ({ title, text, image }) => {
  return (
    <div className="mfa__features-container__feature">
      <div className="mfa__features-container__feature-title">
        <h1>{title}</h1>
        <div />
      </div>
      <div className="mfa__features-container_feature-text">
        <p>{text}</p>
      </div>
      {image && (
        <div className="mfa__features-container_feature-image">
          <img src={image} alt="icon" />
        </div>
      )}
    </div>
  );
};

export default Feature;
