import React, { useEffect, useState } from 'react';
import './App.scss';
import { Routes, Route, useLocation } from 'react-router';
import { Footer, Showcase, OurTeam, Terms, Privacy, Shipping, Sizing, PageNotFound, Contact } from './containers';
import Home from './containers/home';
import { ScrollTopButton } from './components';





function App() {
	const [showButton, setShowButton] = useState(false);
	const { pathname } = useLocation();
	useEffect(() => {
		window.addEventListener("scroll", () => {
			if (window.pageYOffset > 300) {
				setShowButton(true);
			} else {
				setShowButton(false);
			}
		})
		window.scrollTo(0, 0);
	}, [pathname])

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		})
	}
	return (
		<div className="App">
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="contact" element={<Contact />} />
				<Route path="creators" element={<Showcase />} />
				<Route path="our-team" element={<OurTeam />} />
				<Route path="terms" element={<Terms />} />
				<Route path="/legal/terms" element={<Terms />} />
				<Route path="privacy" element={<Privacy />} />
				<Route path="/legal/privacy" element={<Privacy />} />
				<Route path="shipping" element={<Shipping />} />
				<Route path="/pages/shipping-and-returns/" element={<Shipping />} />
				<Route path="sizing" element={<Sizing />} />
				<Route path="/pages/sizes" element={<Sizing />} />
				<Route path="*" element={<PageNotFound />} />
			</Routes>
			{showButton && <ScrollTopButton scrollToTop={scrollToTop} />}
			<Footer />
		</div>

	);
}

export default App;
