import { spoonkid, jeromeasf, barraccudda, fuziondroid, treyten, frustratedgamer, jessetc, digitosim, chillingsmite } from "../../containers/showcase/imports";

export const slideData = [
	{
		index: 0,
		headline: "FuzionDroid",
		button: "Visit Site",
		siteLink: "https://fuziondroid.store/",
		src: fuziondroid,
	},

	{
		index: 1,
		headline: "JeromeASF",
		button: "Visit Site",
		siteLink: "https://jeromeasf.store/",
		src: jeromeasf,
	},
	{
		index: 2,
		headline: "BaRRaCCuDDa",
		button: "Visit Site",
		siteLink: "https://barraccudda.merchforall.com/",
		src: barraccudda,
	},
	{
		index: 3,
		headline: "Spoonkid",
		button: "Visit Site",
		siteLink: "https://www.monke.clothing/",
		src: spoonkid,
	},
	{
		index: 4,
		headline: "DigitoSIM",
		button: "Visit Site",
		siteLink: "https://digitosim.store/",
		src: digitosim,
	},
	{
		index: 5,
		headline: "TheFrustratedGamer",
		button: "Visit Site",
		siteLink: "https://thefrustratedgamer.store/",
		src: frustratedgamer,
	},
	{
		index: 6,
		headline: "Treyten",
		button: "Visit Site",
		siteLink: "https://treyten.store/",
		src: treyten,
	},
	{
		index: 7,
		headline: "JesseTC",
		button: "Visit Site",
		siteLink: "https://jessetc.store/",
		src: jessetc,
	},
	{
		index: 8,
		headline: "ChillingSmite",
		button: "Visit Site",
		siteLink: "https://chillingsmite.store/",
		src: chillingsmite,
	},
];