import React from "react";
import possibilityImage from "../../assets/possibilityImage.jpg";
import Fade from "react-reveal/Fade";
import "./possibility.scss";

const Possibility = () => {
  return (
    <div className="mfa__possibility section__padding">
      <div className="mfa__possibility-image">
        <img src={possibilityImage} height="902px" width="902px" alt="possibility" />
      </div>
      <Fade right cascade>
        <div className="mfa__possibility-content">
          <h4>BRINGING YOUR VISION TO LIFE</h4>
          <h1 className="gradient__text">High Quality Apparel</h1>
          <p>
            MerchForAll has a wide array of products in our catalog. From retail
            quality shirts, hoodies, and more, MerchForAll can source and
            produce any product to your specification. MerchForAll has
            agreements in place with Nike, New Era, and Under Armour to create
            custom gear for both you and your fans! Want a unique plushie, the
            newest trend, or a fully custom windbreaker? Reach out to us, and
            learn more about how we can get you started.
          </p>
          <a href="client-lookbook.pdf" target="_blank" rel="noopener noreferrer">
            <h4>View Products</h4>
          </a>
        </div>
      </Fade>
    </div>
  );
};

export default Possibility;
