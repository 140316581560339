import React from "react";
import formSuccessImg from "../../assets/example.png";
import mfaLogo from "../../assets/textlogo.svg";
import "./formSignup.scss";

const FormSuccess = () => {
  return (
    <div className="form-content-right-success">
      <div className="form-success-text-container">
				<img src={mfaLogo} alt="form-submission" className="form-img-2" style={{padding: "1rem"}}/>
        <h1 className="form-success">We have received your submission!</h1>
        <p className="form-success-text">
          We will be in touch within 48 hours!
          <br />
          <br />
          {/* <p>Join our Discord and a team member will get in touch!</p> */}
        </p>
        {/* <a
          href="https://discord.gg/yqvbAMgmgC"
          rel="noreferrer"
          target="_blank"
        >
          <button className="form-success-joinbutton">Discord</button>
        </a> */}
      </div>

      <img
        className="form-img-2"
        src={formSuccessImg}
        alt="form submission confirmation"
      />
    </div>
  );
};

export default FormSuccess;
