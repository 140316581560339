import React, { useState } from "react";
import Fade from "react-reveal/Fade";
import frontPage1 from "../../assets/frontPage1.png";
import example2 from "../../assets/example2.png";
import example3 from "../../assets/example3.png";
import example4 from "../../assets/example4.png";
import Form from "../form/Form";
import { Carousel as HeaderCarousel } from "react-responsive-carousel";
import "./header.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const Header = () => {
  const headerPhotos = [frontPage1, example2, example3, example4];
  const [emailValue, setEmailValue] = useState("");
  const [open, setOpen] = useState(false);

  function getStartedHandler() {
    setEmailValue(emailValue);
    setOpen(!open);
  }

  return (
    <div className="mfa__header section__padding" id="home">
      <div className="mfa__header-content">
        <h1 className="gradient__text">Next Level Merchandise</h1>
        <h1 className="mfa__header-title_subtext">For All</h1>
        <p>
          Let's combine our expertise with yours. Together, we can leverage your
          content with our merchandising strategies to build out the ultimate
          end to end merchandising solution.
        </p>
        <Fade delay={200} duration={2000}>
          <div className="mfa__header-content__input">
            <button type="button" onClick={getStartedHandler}>
              Get In Touch
            </button>
          </div>
        </Fade>
      </div>
      <Fade delay={200} duration={2000}>
        <div className="mfa__header-image">
          <HeaderCarousel
            autoPlay
            infiniteLoop={true}
            interval={7000}
            showIndicators={false}
            showStatus={false}
            showThumbs={false}
            showArrows={false}
            transitionTime={1000}
          >
            {headerPhotos.map((photo, i) => (
              <img
                key={i}
                src={photo}
                height="974px"
                width="853px"
                alt={`merchforall product sample ${i}`}
              />
            ))}
          </HeaderCarousel>
        </div>
      </Fade>
      <Form
        dialogOpen={open}
        handleClose={getStartedHandler}
        emailValue={emailValue}
      />
    </div>
  );
};

export default Header;
