import React from "react";
import Fade from "react-reveal/Fade";
import merchShelf from "../../assets/merchShelf.jpg";
import "./integrations.scss";

const Integrations = () => {
  return (
    <div className="mfa__integrations section__padding">
      <Fade left cascade>
        <div className="mfa__integrations-content">
          <h4>SOCIAL INTEGRATION</h4>
          <h1 className="gradient__text">YouTube Integration</h1>
          <p>
            Easily integrate your MerchForAll store with YouTube to display your
            products directly under your video and on your channel. Allowing
            your viewers an easy way to see and shop your products from their
            computer or mobile device.
          </p>
        </div>
      </Fade>
      <div className="mfa__integrations-image">
        <img src={merchShelf} alt="integrations" />
      </div>
    </div>
  );
};

export default Integrations;
