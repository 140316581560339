import React, { useState } from "react";
import "./formSignup.scss";
import formImage from "../../assets/formImage.webp";
import FormSignup from "./FormSignup";
import FormSuccess from "./FormSuccess";
import Dialog from "@mui/material/Dialog";
import emailjs from "@emailjs/browser";

const Form = ({ dialogOpen, handleClose, emailValue }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  function submitForm(values) {
    let formValues = {
      username: "Name: " + values.username,
      email: "Email: " + values.email,
      discord: "Discord: " + values.discord,
      socials: "Socials: " + values.socials,
      role: "Role: " + values.role,
    };
    emailjs
      .send(
        "service_h4k5e3m",
        "template_wnw2wqe",
        formValues,
        "hJ2oMBepLUDqr3Ubd"
      )
      .then(
        (result) => {
          console.log("SUCCESS!");
        },
        (error) => {
          console.log(error.text);
        }
      );
    setIsSubmitted(true);
  }

  return (
    <>
      <Dialog
        className="contact-form-dialog"
        fullWidth={true}
        maxWidth="lg"
        open={dialogOpen}
      >
        <div className="form-container">
          <span className="close-btn" onClick={handleClose}>
            ×
          </span>
          {!isSubmitted && (
            <div className="form-content-left">
              <img
                className="form-img"
                width="433px"
                height="650px"
                src={formImage}
                alt="creator contact form"
              />
            </div>
          )}

          {!isSubmitted ? (
            <FormSignup submitForm={submitForm} emailValue={emailValue} />
          ) : (
            <FormSuccess />
          )}
        </div>
      </Dialog>
    </>
  );
};

export default Form;
