import React from "react";
import { Feature } from "../../components";
import Fade from "react-reveal/Fade";
import clothes from "../../assets/clothes.jpg";
import "./features.scss";

const featuresData = [
  {
    title: "BRANDED WEB STORE",
    icon: "fas fa-store fa-lg",
    text: "We all know the importance of having your own branding and logo on your online store. With MerchForAll you'll get your own online web store, fully customized in appearance and functionality to fit your brand.",
  },
  {
    title: "MOBILE WEB APP",
    icon: "fas fa-mobile-alt fa-lg",
    text: "Mobile online shopping has the highest conversion rates of any device. MerchForAll will establish an accessible web store, tailored to your branding, for any device.",
  },
  {
    title: "SUPPORT ON ANY PLATFORM",
    icon: "fas fa-check fa-lg",
    text: "With MerchForAll we handle all customer support. Your fans can seek order support via any major social media platform, as well as conventional email. With quick turnaround times, fans can seek support anywhere, anytime, globally.",
  },
  {
    title: "INFLUENCER DASHBOARD",
    icon: "fas fa-chart-line fa-lg",
    text: "The MerchForAll influencer dashboard gives full control to you. Want to see the order processing, sales, and real-time turnover information. Just log in, and have full access, for full transparency.",
  },
  {
    title: "SOCIAL MEDIA INTEGRATION",
    icon: "fas fa-tshirt fa-lg",
    text: "MerchForAll has full integration with YouTube Merchandise and support for live streaming purchase alerts on any platform. With the ability to fully customize your alert to match your branding.",
  },
  {
    title: "MERCHANDISE WAREHOUSING & FULFILLMENT",
    icon: "fas fa-tshirt fa-lg",
    text: "Selling your products is one thing, but order fulfillment is a challenge for many. MerchForAll has quick global shipping and distribution. With agreements with local US carriers, as well as major global shipping solutions, your goods are in the right hands. Whether we need to ship to Kansas, London, or China, MerchForAll will get it there for you!",
  },
];

const Features = () => {
  return (
    <div className="mfa__features section__padding section__padding-features" id="features">
      <div className="mfa__features-heading">
        <Fade left cascade>
          <div className="mfa__features-heading-text">
            <h1 className="gradient__text">Our Solutions</h1>
            <p>
              Custom tailored to your brands vision, goals, and desired
              products, Merch For All plugs into your daily routine like a
              second cup of coffee.
            </p>
          </div>
        </Fade>
        <div className="mfa__features-heading-image-parent">
          <div className="mfa__features-heading-image-container">
            <img
              src={clothes}
              alt="MerchForAll Features Showcase"
              className="mfa__features-heading-image"
            />
          </div>
        </div>
      </div>
      <Fade delay={200} duration={2000}>
        <div className="mfa__features-container">
          {featuresData.map((item, i) => (
            <div className="mfa__features-image-container">
              <Feature
                title={item.title}
                text={item.text}
                key={item.title + i}
              />
            </div>
          ))}
        </div>
      </Fade>
    </div>
  );
};

export default Features;
