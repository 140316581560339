import React from "react";
import "./multiheader.scss";

const MultiHeader = ({ title, desc }) => {
  return (
    <div className="mfa__multiHeader section__padding">
      <div className="mfa__multiHeader-content">
        <h1 className="gradient__text">{title}</h1>
        <p>{desc}</p>
      </div>
    </div>
  );
};

export default MultiHeader;
