import React from "react";
import { Navbar, Card } from "../../components";
import {
  cardDataOne,
  cardDataThree,
  cardDataTwo,
  cardDataFour,
} from "../../components/card/data";

import OurTeamHeader from "./OurTeamHeader";

const OurTeam = () => {
  return (
    <div>
      <div className="gradient__bg" id="team">
        <Navbar />
        <OurTeamHeader />
      </div>

      <Card {...cardDataOne} />
      <Card {...cardDataTwo} />
      {/* <Card {...cardDataThree} />
      <Card {...cardDataFour} /> */}
    </div>
  );
};

export default OurTeam;
