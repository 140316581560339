import React from "react";
import CreatorsHeader from "../creatorsheader/CreatorsHeader";
import Slider from "../../components/slider/Slider";
import { Navbar } from "../../components";
import "./showcase.scss";
import { slideData } from "../../components/slider/slideData";

const Showcase = () => {
  return (
    <div>
      <div className="gradient__bg">
        <Navbar />
        <CreatorsHeader />
      </div>

      <div className="mfa__showcase section__padding" id="showcase">
        <div className="mfa__showcase-heading">
          <h1 className="gradient__text">Your brand. Your Merch.</h1>
        </div>
        <div className="mfa__showcase-container">
          <Slider heading="Example" slides={slideData} />
        </div>
      </div>
    </div>
  );
};

export default Showcase;
