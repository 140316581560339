import React from "react";
import Fade from "react-reveal/Fade";
import "./card.scss";

const Card = ({
  imgLink,
  title,
  description,
  description2,
  description3,
  fullName,
}) => {
  return (
    <div className="section__padding" id="team">
      <div className="mfa__team">
        <Fade left>
          <div className="mfa__team-photo">
            <img src={imgLink} alt="MerchForAll Employee" />
          </div>
        </Fade>
        <Fade top cascade>
          <div className="mfa__team-content">
            <h1>{fullName}</h1>
            <h3>{title}</h3>
            <p>{description}</p>
            <br />
            <p>{description2}</p>
            <br />
            <p>{description3}</p>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default Card;
