import React from "react";
import { Navbar } from "../../components";
import MultiHeader from "../multiheader/MultiHeader";
import sizingChart from "../../assets/sizes.svg";
import "./sizing.scss";

const Sizing = () => {
  return (
    <div>
      <div className="gradient__bg" id="sizing">
        <Navbar />
        <MultiHeader
          title="Sizing Information"
          desc="Learn more about our product sizing and fit"
        />
      </div>
      <div className="mfa__Sizing section__padding">
        <div className="mfa__Sizing-container">
          <p className="mfa__Sizing-title">
            The Merch For All Team suggests ordering the same size usually worn
            in the following products. Below are averages we have received from
            our suppliers, please keep in mind the actual product size may
            differ!
          </p>
          <br />
          <div className="mfa__Sizing-image-container">
            <img src={sizingChart} alt="MerchForAll Sizing Guide" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sizing;
