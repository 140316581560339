import React from "react";
import { Navbar } from "../../components";
import MultiHeader from "../multiheader/MultiHeader";
import "./shipping.scss";

import { shippingText } from "./shippingText";

const Shipping = () => {
  return (
    <div>
      <div className="gradient__bg" id="shipping">
        <Navbar />
        <MultiHeader
          title="Shipping & Returns"
          desc="Learn more about our shipping and return procedures below."
        />
      </div>
      <div className="mfa__Shipping section__padding">
        <div className="mfa__Shipping-container">
          <p className="mfa__Shipping-title">
            Orders are sent out via USPS and are typically processed within five
            business days of purchase, excluding signed merchandise. Delivery
            time is dependent upon location and can take up to 21 business days.
          </p>
          <br />
          {shippingText.map((text) => {
            return (
              <div className="mfa__Shipping-text-group">
                <h3>{text.heading}</h3>
                <p>{text.description}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Shipping;
