import React from "react";
import { Navbar } from "../../components";
import MultiHeader from "../multiheader/MultiHeader";
import "./terms.scss";

import { termsText } from "./termsText";

const Legal = () => {
  return (
    <div>
      <div className="gradient__bg">
        <Navbar />
        <MultiHeader
          title="Terms & Conditions"
          desc="Please carefully review these Terms and Conditions of Use Before using this site. Any use of this website creates a binding agreement to comply with these Terms and Conditions. If you do not agree with these terms, please exit this site immediately."
        />
      </div>
      <div className="mfa__Terms section__padding">
        <div className="mfa__Terms-container">
          {termsText.map((text) => {
            return (
              <div className="mfa__Terms-text-group">
                <h3>{text.heading}</h3>
                <p>{text.description}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Legal;
