// =========================
// Slide
// =========================
import React from "react";
import "./slide.styles.scss";

export default class Slide extends React.Component {
  constructor(props) {
    super(props);

    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleSlideClick = this.handleSlideClick.bind(this);
    this.imageLoaded = this.imageLoaded.bind(this);
    this.slide = React.createRef();
  }

  handleMouseMove(event) {
    const el = this.slide.current;
    const r = el.getBoundingClientRect();

    el.style.setProperty(
      "--x",
      event.clientX - (r.left + Math.floor(r.width / 2))
    );
    el.style.setProperty(
      "--y",
      event.clientY - (r.top + Math.floor(r.height / 2))
    );
  }

  handleMouseLeave(event) {
    this.slide.current.style.setProperty("--x", 0);
    this.slide.current.style.setProperty("--y", 0);
  }

  handleSlideClick(event) {
    this.props.handleSlideClick(this.props.slide.index);
  }

  imageLoaded(event) {
    event.target.style.opacity = 1;
  }

  render() {
    const { src, button, headline, index, siteLink } = this.props.slide;
    const current = this.props.current;
    let classNames = "carousel-slide";

    if (current === index) classNames += " carousel-slide--current";
    else if (current - 1 === index) classNames += " carousel-slide--previous";
    else if (current + 1 === index) classNames += " carousel-slide--next";

    return (
      <li
        ref={this.slide}
        className={classNames}
        onClick={this.handleSlideClick}
        onMouseMove={this.handleMouseMove}
        onMouseLeave={this.handleMouseLeave}
      >
        <div className="carousel-slide__image-wrapper">
          <img
            className="carousel-slide__image"
            alt={headline}
            src={src}
            onLoad={this.imageLoaded}
            height="300"
            width="300"
          />
        </div>

        <article className="carousel-slide__content">
          <h2 className="carousel-slide__headline">{headline}</h2>
          <button className="carousel-slide__action btn">
            <a href={siteLink} target="_blank" rel="noopener noreferrer">
              {button}
            </a>
          </button>
        </article>
      </li>
    );
  }
}
